<template>
  <div class="wrapper-container">
    <div class="describe-container-box">
      <div class="title">新零售</div>
      <div class="sub-title">零售商数智化技术产品&解决方案</div>
      <img
        class="main-image"
        src="@/image/retail/img_banner_retail@3x.png"
        alt=""
      />
      <div class="describe">
        适用于综合大卖场、连锁标准超市、便利店、超市+百货、专卖店、超市+餐饮等零售业态。
      </div>
      <div class="introduce">
        全球蛙通过在技术赋能上迭代升级，配套线下全流程经营系统，为区域零售企业打造全域全渠道一体化双效增长模式，重磅推出一体化产品
        ·
        十大管理平台，用数智化赋能区域零售企业管理、业务效率提升，让零售商实现门店、商品、会员、订单、导购、库存、营销、支付、管理等各要素的精细化在线管理，同时在亿元新爆品打造和全域全渠道营销的加持下，为零售企业搭建一条从公域到私域再到全域的全新路闭环链，以数据驱动零售商精进运营，完成线上线下、公域私域的全面融合，实现全域全渠道数智化生意增长。
      </div>
    </div>
    <div
      class="describe-container-box"
      @click="$router.push('/retail/service-platform')"
    >
      <div
        class="sub-title"
        style="line-height: 22px; color: #222; font-size: 20px"
      >
        美蛙零售数智化业务平台
      </div>
      <div
        class="describe"
        style="
          margin: 5px 0;
          line-height: 22px;
          color: #999;
          font-size: 14px;
          font-family: PingFang SC-Regular;
        "
      >
        一体化十大管理平台，矩阵丰富，保驾护航
      </div>
      <div
        class="programme-item-detail"
        style="margin-bottom: 20px; line-height: 17px; margin-top: 0"
      >
        <span>了解更多</span>
        <img src="@/image/homeContainer/btn_more_home@3x.png" alt="" />
      </div>
    </div>
    <img class="large-image-h1226" src="@/image/retail/img_one_retail@3x.png" />
    <img
      class="large-image-h1268"
      style="margin-bottom: 30px"
      src="@/image/retail/img_two_retail@3x.png"
    />
    <div class="func-module-container">
      <div class="func-title">六大服务聚势赋能</div>
      <div class="func-sub-title">助力零售商全域全渠道生意增长</div>
      <div class="func-image-container">
        <img
          v-for="func in funcModules"
          :src="func.src"
          :key="func.path"
          @click="$router.push(func.path)"
        />
      </div>
    </div>
    <div class="enable-container">
      <div class="enable-tatile">利他赋能·共享共赢</div>
      <div class="enable-sub-title">助力零售商业绩实现新增长</div>
      <div class="enable-describe">
        全球蛙始终坚持利他赋能的初心不改，将继续围绕零售商数智化发展实际需求，真正做到想客户之所想、急客户之所急、解客户之所忧，持续做好零售商全域全渠道数智化赋能方案，加强自身业务服务的广度和深度，积极拓展新的增长资源和渠道，加强对客户的赋能与助力，用“三融五通六赋能”助力零售商全域全渠道生意增长，携手万家商超·万家厂牌的百万导购为亿万用户提供高品质高性价比的全球好货和专业贴心的全渠道服务！
      </div>
      <div class="enable-func-cantainer">
        <div
          class="enable-func-item"
          v-for="(func, funcIndex) in enableFuncs"
          :key="`${func.bg}${funcIndex}`"
          :style="{
            backgroundColor: func.bg,
          }"
        >
          {{ func.text }}
        </div>
      </div>
      <div class="map-container" style="width: 100%; height: 278px"></div>
      <van-swipe class="swiper-wrapper">
        <van-swipe-item v-for="swipe in swipeImages" :key="swipe">
          <img :src="swipe" alt="" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <contact-us />
    <action-sheet :menu="enumerableRoutes" />
  </div>
</template>

<script>
import enumerableRoutesMixin from "@/mixins/enumerableRoutes";
import * as echarts from "echarts";
import "echarts/map/js/china";
export default {
  mixins: [enumerableRoutesMixin],
  data: () => ({
    funcModules: [
      {
        path: "/retail/technical-power",
        src: require("@/image/retail/img_power_retail@3x.png"),
      },
      {
        path: "/retail/data-power",
        src: require("@/image/retail/img_data_retail_icon@3x.png"),
      },
      {
        path: "/retail/operate-power",
        src: require("@/image/retail/img_capability_retail@3x.png"),
      },
      {
        path: "/retail/marketing-power",
        src: require("@/image/retail/img_marketing_retail@3x.png"),
      },
      {
        path: "/retail/commodity-power",
        src: require("@/image/retail/img_commodity_retail@3x.png"),
      },
      {
        path: "/retail/explosives-power",
        src: require("@/image/retail/img_explosives_retail@3x.png"),
      },
    ],
    enableFuncs: [
      {
        text: "全场景全链路技术支撑",
        bg: "#0682ce",
      },
      {
        text: "多渠道多平台数据汇总",
        bg: "#0cbdad",
      },
      {
        text: "全域全渠道 运营服务",
        bg: "#0ed69e",
      },
      {
        text: "支付侧品牌侧营销支撑",
        bg: "#0682ce",
      },
      {
        text: "全国货全球货商品补位",
        bg: "#0cbdad",
      },
      {
        text: "新包装新营销造品升级",
        bg: "#0ed69e",
      },
    ],
    swipeImages: [
      require("@/image/retail/img_one_map_retail@3x.png"),
      require("@/image/retail/img_two_map_retail@3x.png"),
      require("@/image/retail/img_three_map_retail@3x.png"),
    ],
    mapData: [
      { name: "北京", value: 100 },
      { name: "上海", value: 0 },
      { name: "西安", value: 0 },
      { name: "深圳", value: 0 },
      { name: "广州", value: 0 },
      { name: "武汉", value: 200 },
      { name: "杭州", value: 0 },
      { name: "郑州", value: 0 },
      { name: "南京", value: 0 },
      { name: "天津", value: 100 },
      { name: "苏州", value: 0 },
      { name: "成都", value: 20 },
      { name: "长沙", value: 100 },
      { name: "合肥", value: 0 },
      { name: "宁波", value: 0 },
      { name: "福州", value: 200 },
      { name: "青岛", value: 66 },
      { name: "泉州", value: 0 },
      { name: "厦门", value: 0 },
      { name: "东莞", value: 0 },
      { name: "重庆", value: 10 },
      { name: "南通", value: 0 },
      { name: "佛山", value: 0 },
      { name: "无锡", value: 0 },
      { name: "济南", value: 0 },
      { name: "温州", value: 0 },
      { name: "嘉兴", value: 0 },
      { name: "南昌", value: 0 },
      { name: "昆明", value: 0 },
      { name: "太原", value: 200 },
      { name: "绍兴", value: 0 },
      { name: "南宁", value: 100 },
      { name: "扬州", value: 0 },
      { name: "兰州", value: 0 },
      { name: "石家庄", value: 0 },
      { name: "廊坊", value: 0 },
      { name: "沈阳", value: 0 },
      { name: "徐州", value: 0 },
      { name: "常州", value: 0 },
      { name: "金华", value: 0 },
      { name: "中山", value: 0 },
      { name: "保定", value: 0 },
      { name: "长春", value: 0 },
      { name: "哈尔滨", value: 0 },
      { name: "盐城", value: 0 },
      { name: "台州", value: 0 },
      { name: "潍坊", value: 0 },
      { name: "惠州", value: 0 },
      { name: "贵阳", value: 200 },
      { name: "大连", value: 0 },
      { name: "淮安", value: 0 },
      { name: "泰州", value: 0 },
      { name: "湖州", value: 0 },
      { name: "银川", value: 0 },
      { name: "唐山", value: 0 },
      { name: "邯郸", value: 200 },
      { name: "镇江", value: 0 },
      { name: "宿迁", value: 0 },
      { name: "阜阳", value: 40 },
      { name: "莆田", value: 0 },
      { name: "烟台", value: 0 },
      { name: "济宁", value: 0 },
      { name: "临沂", value: 0 },
      { name: "洛阳", value: 0 },
      { name: "汕头", value: 0 },
      { name: "江门", value: 0 },
      { name: "海口", value: 0 },
      { name: "沧州", value: 0 },
      { name: "呼和浩特", value: 10 },
      { name: "连云港", value: 0 },
      { name: "许昌", value: 0 },
      { name: "珠海", value: 0 },
      { name: "湛江", value: 0 },
      { name: "咸阳", value: 0 },
      { name: "邢台", value: 0 },
      { name: "包头", value: 0 },
      { name: "芜湖", value: 0 },
      { name: "滁州", value: 0 },
      { name: "漳州", value: 0 },
      { name: "淄博", value: 0 },
      { name: "日照", value: 0 },
      { name: "开封", value: 0 },
      { name: "黄石", value: 0 },
      { name: "襄阳", value: 0 },
      { name: "荆州", value: 0 },
      { name: "衡阳", value: 0 },
      { name: "肇庆", value: 0 },
      { name: "梅州", value: 0 },
      { name: "揭阳", value: 0 },
      { name: "柳州", value: 0 },
      { name: "桂林", value: 0 },
      { name: "三亚", value: 0 },
      { name: "宝鸡", value: 0 },
      { name: "渭南", value: 0 },
      { name: "榆林", value: 0 },
      { name: "西宁", value: 50 },
      { name: "秦皇岛", value: 100 },
      { name: "衡水", value: 0 },
      { name: "鞍山", value: 0 },
      { name: "丹东", value: 0 },
      { name: "吉林", value: 0 },
      { name: "衢州", value: 0 },
      { name: "舟山", value: 0 },
      { name: "安庆", value: 0 },
      { name: "六安", value: 0 },
      { name: "亳州", value: 0 },
      { name: "宁德", value: 0 },
      { name: "萍乡", value: 0 },
      { name: "九江", value: 0 },
      { name: "赣州", value: 0 },
      { name: "泰安", value: 0 },
      { name: "德州", value: 0 },
      { name: "聊城", value: 0 },
      { name: "菏泽", value: 0 },
      { name: "平顶山", value: 0 },
      { name: "焦作", value: 0 },
      { name: "漯河", value: 4 },
      { name: "南阳", value: 0 },
      { name: "信阳", value: 0 },
      { name: "周口", value: 0 },
      { name: "驻马店", value: 0 },
      { name: "宜昌", value: 0 },
      { name: "荆门", value: 0 },
      { name: "孝感", value: 0 },
      { name: "株洲", value: 0 },
      { name: "湘潭", value: 0 },
      { name: "岳阳", value: 0 },
      { name: "常德", value: 0 },
      { name: "娄底", value: 0 },
      { name: "茂名", value: 0 },
      { name: "河源", value: 0 },
      { name: "清远", value: 0 },
      { name: "云浮", value: 0 },
      { name: "北海", value: 0 },
      { name: "钦州", value: 0 },
      { name: "攀枝花", value: 0 },
      { name: "绵阳", value: 0 },
      { name: "张家口", value: 50 },
      { name: "承德", value: 0 },
      { name: "大同", value: 0 },
      { name: "长治", value: 0 },
      { name: "晋城", value: 200 },
      { name: "晋中", value: 100 },
      { name: "运城", value: 0 },
      { name: "忻州", value: 0 },
      { name: "临汾", value: 0 },
      { name: "吕梁", value: 0 },
      { name: "乌海", value: 0 },
      { name: "赤峰", value: 0 },
      { name: "通辽", value: 0 },
      { name: "鄂尔多斯", value: 200 },
      { name: "呼伦贝尔", value: 0 },
      { name: "本溪", value: 0 },
      { name: "锦州", value: 0 },
      { name: "盘锦", value: 0 },
      { name: "铁岭", value: 0 },
      { name: "四平", value: 0 },
      { name: "通化", value: 0 },
      { name: "白山", value: 0 },
      { name: "松原", value: 0 },
      { name: "延边朝鲜自治州", value: 0 },
      { name: "齐齐哈尔", value: 0 },
      { name: "大庆", value: 0 },
      { name: "牡丹江", value: 0 },
      { name: "蚌埠", value: 0 },
      { name: "淮南", value: 0 },
      { name: "马鞍山", value: 0 },
      { name: "淮北", value: 0 },
      { name: "铜陵", value: 0 },
      { name: "宿州", value: 0 },
      { name: "宣城", value: 0 },
      { name: "南平", value: 0 },
      { name: "龙岩", value: 0 },
      { name: "景德镇", value: 0 },
      { name: "新余", value: 0 },
      { name: "鹰潭", value: 0 },
      { name: "吉安", value: 0 },
      { name: "宜春", value: 0 },
      { name: "抚州", value: 0 },
      { name: "上饶", value: 0 },
      { name: "枣庄", value: 0 },
      { name: "东营", value: 0 },
      { name: "滨州", value: 0 },
      { name: "安阳", value: 0 },
      { name: "鹤壁", value: 0 },
      { name: "新乡", value: 0 },
      { name: "濮阳", value: 0 },
      { name: "三门峡", value: 0 },
      { name: "商丘", value: 0 },
      { name: "济源", value: 0 },
      { name: "十堰", value: 0 },
      { name: "鄂州", value: 0 },
      { name: "黄冈", value: 0 },
      { name: "咸宁", value: 0 },
      { name: "随州", value: 0 },
      { name: "恩施土家族苗族自治州", value: 0 },
      { name: "仙桃", value: 0 },
      { name: "天门", value: 0 },
      { name: "邵阳", value: 0 },
      { name: "张家界", value: 0 },
      { name: "益阳", value: 0 },
      { name: "郴州", value: 0 },
      { name: "永州", value: 0 },
      { name: "汕尾", value: 0 },
      { name: "阳江", value: 0 },
      { name: "防城港", value: 0 },
      { name: "贵港", value: 0 },
      { name: "玉林", value: 0 },
      { name: "百色", value: 0 },
      { name: "儋州", value: 0 },
      { name: "自贡", value: 0 },
      { name: "泸州", value: 0 },
      { name: "德阳", value: 0 },
      { name: "遂宁", value: 0 },
      { name: "乐山", value: 0 },
      { name: "南充", value: 0 },
      { name: "眉山", value: 0 },
      { name: "宜宾", value: 0 },
      { name: "达州", value: 0 },
      { name: "遵义", value: 0 },
      { name: "黔西南布依族苗族自治州", value: 0 },
      { name: "黔东南布依族苗族自治州", value: 0 },
      { name: "黔南布依族苗族自治州", value: 0 },
      { name: "曲靖市", value: 0 },
      { name: "丽江市", value: 0 },
      { name: "普洱市", value: 0 },
      { name: "大理白族自治州", value: 200 },
      { name: "铜川", value: 0 },
      { name: "延安", value: 0 },
      { name: "汉中", value: 0 },
      { name: "安康", value: 0 },
      { name: "商洛", value: 0 },
      { name: "武威", value: 0 },
      { name: "平凉", value: 0 },
      { name: "酒泉", value: 0 },
      { name: "庆阳", value: 0 },
      { name: "定西", value: 0 },
      { name: "石嘴山", value: 0 },
      { name: "固源", value: 0 },
    ],
    geoCoordMap: {
      北京: [116.41, 40.19],
      上海: [121.47, 31.24],
      西安: [108.79, 34.11],
      深圳: [114.13, 22.65],
      广州: [113.54, 23.35],
      武汉: [114.34, 30.62],
      杭州: [119.47, 29.9],
      郑州: [113.47, 34.63],
      南京: [118.84, 31.93],
      天津: [117.33, 39.29],
      苏州: [120.65, 31.38],
      成都: [103.93, 30.65],
      长沙: [113.15, 28.23],
      合肥: [117.36, 31.76],
      宁波: [121.46, 29.74],
      福州: [119.18, 26.05],
      青岛: [120.14, 36.45],
      泉州: [118.26, 25.2],
      厦门: [118.12, 24.68],
      东莞: [113.88, 22.94],
      重庆: [107.87, 30.06],
      南通: [121.02, 32.19],
      佛山: [112.94, 23.01],
      无锡: [120.08, 31.53],
      济南: [117.21, 36.64],
      温州: [120.44, 27.9],
      嘉兴: [120.8, 30.62],
      南昌: [116.02, 28.65],
      昆明: [102.87, 25.39],
      太原: [112.32, 37.96],
      绍兴: [120.64, 29.74],
      南宁: [108.46, 23.06],
      扬州: [119.47, 32.74],
      兰州: [103.65, 36.35],
      石家庄: [114.44, 38.13],
      廊坊: [116.63, 39.26],
      沈阳: [123.14, 42.09],
      徐州: [117.52, 34.36],
      常州: [119.64, 31.63],
      金华: [119.95, 29.12],
      中山: [113.38, 22.52],
      保定: [115.17, 39.02],
      长春: [125.77, 44.38],
      哈尔滨: [127.96, 45.64],
      盐城: [120.18, 33.51],
      台州: [121.11, 28.77],
      潍坊: [119.07, 36.55],
      惠州: [114.5, 23.24],
      贵阳: [106.71, 26.84],
      大连: [122.21, 39.59],
      淮安: [118.96, 33.35],
      泰州: [120.06, 32.57],
      湖州: [119.87, 30.74],
      银川: [106.35, 38.28],
      唐山: [118.34, 39.72],
      邯郸: [114.54, 36.55],
      镇江: [119.45, 32.02],
      宿迁: [118.52, 33.78],
      阜阳: [115.7, 32.92],
      莆田: [118.89, 25.45],
      烟台: [120.8, 37.24],
      济宁: [116.73, 35.37],
      临沂: [118.28, 35.31],
      洛阳: [112.03, 34.29],
      汕头: [116.58, 23.33],
      江门: [112.67, 22.28],
      海口: [110.42, 19.83],
      沧州: [116.75, 38.27],
      呼和浩特: [111.5, 40.59],
      连云港: [119.13, 34.53],
      许昌: [113.71, 34.07],
      珠海: [113.36, 22.17],
      湛江: [110.16, 21.1],
      咸阳: [108.39, 34.78],
      邢台: [114.82, 37.21],
      包头: [110.26, 41.55],
      芜湖: [118.13, 31.16],
      滁州: [118.1, 32.54],
      漳州: [117.44, 24.37],
      淄博: [118.05, 36.61],
      日照: [119.14, 35.58],
      开封: [114.54, 34.6],
      黄石: [115.03, 29.93],
      襄阳: [111.94, 31.93],
      荆州: [112.57, 30.0],
      衡阳: [112.57, 26.79],
      肇庆: [112.21, 23.54],
      梅州: [116.08, 24.2],
      揭阳: [116.12, 23.34],
      柳州: [109.37, 24.95],
      桂林: [110.51, 25.35],
      三亚: [109.42, 18.39],
      宝鸡: [107.2, 34.38],
      渭南: [109.85, 34.95],
      榆林: [109.57, 38.04],
      西宁: [101.44, 36.83],
      秦皇岛: [119.19, 40.09],
      衡水: [115.82, 37.76],
      鞍山: [123.01, 40.71],
      丹东: [124.4, 40.54],
      吉林: [126.84, 43.58],
      衢州: [118.67, 28.93],
      舟山: [122.19, 30.09],
      安庆: [116.49, 30.58],
      六安: [116.23, 31.66],
      亳州: [116.18, 33.44],
      宁德: [119.47, 26.97],
      萍乡: [113.9, 27.51],
      九江: [115.45, 29.32],
      赣州: [115.27, 25.71],
      泰安: [117.03, 36.0],
      德州: [116.65, 37.25],
      聊城: [115.88, 36.46],
      菏泽: [115.69, 35.15],
      平顶山: [113.01, 33.8],
      焦作: [113.13, 35.12],
      漯河: [113.9, 33.66],
      南阳: [112.28, 33.04],
      信阳: [114.88, 32.08],
      周口: [114.88, 33.72],
      驻马店: [114.15, 32.92],
      宜昌: [111.14, 30.75],
      荆门: [112.61, 31.07],
      孝感: [113.88, 31.12],
      株洲: [113.52, 27.12],
      湘潭: [112.6, 27.73],
      岳阳: [113.25, 29.07],
      常德: [111.52, 29.3],
      娄底: [111.61, 27.74],
      茂名: [110.95, 22.02],
      河源: [114.96, 24.04],
      清远: [112.87, 24.31],
      云浮: [111.79, 22.82],
      北海: [109.33, 21.68],
      钦州: [109.02, 22.18],
      攀枝花: [101.73, 26.81],
      绵阳: [104.7, 31.85],
      张家口: [115.03, 40.87],
      承德: [117.55, 41.35],
      大同: [113.72, 39.9],
      长治: [112.92, 36.48],
      晋城: [112.71, 35.61],
      晋中: [112.96, 37.33],
      运城: [111.06, 35.19],
      忻州: [112.41, 38.88],
      临汾: [111.38, 36.23],
      吕梁: [111.26, 37.68],
      乌海: [106.87, 39.43],
      赤峰: [118.87, 43.22],
      通辽: [121.57, 43.82],
      鄂尔多斯: [108.63, 39.41],
      呼伦贝尔: [121.2, 49.66],
      本溪: [124.57, 41.23],
      锦州: [121.61, 41.46],
      盘锦: [122.0, 41.08],
      铁岭: [124.17, 42.64],
      四平: [124.47, 43.57],
      通化: [125.95, 41.96],
      白山: [127.29, 42.07],
      松原: [124.48, 44.8],
      延边朝鲜族自治州: [129.12, 43.14],
      齐齐哈尔: [124.55, 47.7],
      大庆: [124.7, 46.35],
      牡丹江: [129.89, 44.6],
      蚌埠: [117.33, 33.11],
      淮南: [116.77, 32.47],
      马鞍山: [118.37, 31.64],
      淮北: [116.74, 33.73],
      铜陵: [117.56, 30.88],
      宿州: [117.21, 33.86],
      宣城: [118.85, 30.69],
      南平: [118.14, 27.34],
      龙岩: [116.74, 25.29],
      景德镇: [117.27, 29.31],
      新余: [114.85, 27.85],
      鹰潭: [117.11, 28.22],
      吉安: [114.82, 26.97],
      宜春: [114.97, 28.31],
      抚州: [116.43, 27.51],
      上饶: [117.47, 28.77],
      枣庄: [117.39, 34.92],
      东营: [118.6, 37.62],
      滨州: [117.84, 37.53],
      安阳: [114.35, 35.88],
      鹤壁: [114.31, 35.72],
      新乡: [114.09, 35.26],
      濮阳: [115.28, 35.81],
      三门峡: [111.11, 34.36],
      商丘: [115.7, 34.29],
      济源: [112.39, 35.1],
      十堰: [110.44, 32.46],
      鄂州: [114.76, 30.33],
      黄冈: [115.34, 30.72],
      咸宁: [114.18, 29.62],
      随州: [113.42, 31.83],
      恩施土家族苗族自治州: [109.5, 30.19],
      仙桃: [113.38, 30.29],
      天门: [113.11, 30.64],
      邵阳: [110.87, 26.91],
      张家界: [110.53, 29.39],
      益阳: [111.95, 28.58],
      郴州: [113.14, 25.81],
      永州: [111.73, 25.77],
      汕尾: [115.53, 23.01],
      阳江: [111.77, 22.04],
      防城港: [108.0, 21.88],
      贵港: [110.0, 23.31],
      玉林: [110.18, 22.44],
      百色: [106.29, 23.99],
      儋州: [109.4, 19.57],
      自贡: [104.68, 29.29],
      泸州: [105.67, 28.44],
      德阳: [104.44, 31.13],
      遂宁: [105.47, 30.63],
      乐山: [103.57, 29.22],
      南充: [106.2, 31.2],
      眉山: [103.76, 29.93],
      宜宾: [104.64, 28.57],
      达州: [107.64, 31.37],
      遵义: [107.08, 28.17],
      黔西南布依族苗族自治州: [105.46, 25.27],
      黔东南苗族侗族自治州: [108.56, 26.51],
      黔南布依族苗族自治州: [107.25, 26.01],
      曲靖: [103.94, 25.7],
      丽江: [100.52, 26.94],
      普洱: [100.72, 23.19],
      大理白族自治州: [100.04, 25.73],
      铜川: [109.03, 35.2],
      延安: [109.32, 36.44],
      汉中: [107.1, 33.09],
      安康: [108.93, 32.76],
      商洛: [109.9, 33.65],
      武威: [103.06, 38.17],
      平凉: [106.65, 35.31],
      酒泉: [96.06, 40.26],
      庆阳: [107.59, 36.16],
      定西: [104.45, 35.12],
      石嘴山: [106.51, 39.01],
      固源: [110.43, 32.13],
    },
  }),
  mounted() {
    const _this = this;
    var myChart = echarts.init(document.querySelector(".map-container"));
    myChart.setOption({
      geo: {
        map: "china",
        label: {
          emphasis: {
            show: false,
          },
        },
        zoom: 1.2,
        itemStyle: {
          normal: {
            // areaColor: "#d1f3ff",
            areaColor: "#D4E1EE",
            borderColor: "#fff",
          },
          emphasis: {
            areaColor: "#00B0FF",
          },
        },
        regions: [
          {
            name: "西藏",
            itemStyle: {
              areaColor: "#EAEBEC",
            },
          },
          {
            name: "台湾",
            itemStyle: {
              areaColor: "#EAEBEC",
            },
          },
        ],
      },
      series: [
        {
          type: "scatter",
          coordinateSystem: "geo",
          data: _this.convertData(_this.mapData),
          symbolSize: function (val) {
            return Math.min(val[2] / 2, 4);
          },
          itemStyle: {
            normal: {
              color: "#5E99FF",
            },
          },
        },
        {
          name: "Top 10",
          type: "effectScatter",
          coordinateSystem: "geo",
          data: _this.convertData(
            _this.mapData
              .sort(function (a, b) {
                return b.value - a.value;
              })
              .slice(0, 10)
          ),
          symbolSize: function (val) {
            return val[2] / 12;
          },
          showEffectOn: "render",
          rippleEffect: {
            brushType: "stroke",
          },
          hoverAnimation: true,
          itemStyle: {
            normal: {
              // color: "#00B0FF",
              color: "#5E99FF",
              shadowBlur: 0,
            },
          },
          zlevel: 1,
        },
      ],
    });
    window.addEventListener("resize", function () {
      myChart.resize();
    });
  },
  methods: {
    convertData(data) {
      var res = [];
      for (var i = 0; i < data.length; i++) {
        var geoCoord = this.geoCoordMap[data[i].name];
        if (geoCoord) {
          res.push({
            name: data[i].name,
            value: geoCoord.concat(data[i].value),
          });
        }
      }
      return res;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/solution.common.scss";
.introduce {
  color: $textColor999 !important;
  margin-bottom: 60px !important;
}
</style>
